import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '92%',
    height: '600px',
    display: 'inline-block'
};

const center = {
    lat: 41.63121011096636,
    lng: 2.2916406980440738
};

function GoogleMaps() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDhIqbDSN0tg1SrQTdz5Vi3-S5K3PDFxcc"
    })

    const [, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        map.setZoom(10)
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback() {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker position={center} />
        </GoogleMap>
    ) : <></>
}

export default React.memo(GoogleMaps)