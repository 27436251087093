import ContactArea from './contactPage.style';
import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import Input from 'common/components/Input';
import Button from 'common/components/Button';
import 'rc-tabs/assets/index.css';
import emailjs from "@emailjs/browser";
import { NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-svg-core/styles.css';
import { faLocationPin, faPhone, faEnvelope, faClock } from "@fortawesome/free-solid-svg-icons";
import GoogleMaps from 'common/components/GoogleMapReact';

const ContactPage = ({
     row,
     col,
     btnStyle,
     contentWrapper,
     sectionSubTitle,
     sectionHeader
 }) => {

    const SubmitButtonGroup = () => (
        <Fragment>
            <Button className="default display-block mt-6" title="ENVIAR" type="submit" {...btnStyle} />
        </Fragment>
    );

    const LabelTextPrivacyPolicy = () => (
        <div className="display-inline">
            <Input inputType="checkbox" isMaterial inputName="privacity" className="display-inline form-control" required />
            <span className="ml-10">Acepto la <a className="text-ckeckbox-privacity" href='/politica-privacidad'>política de privacidad</a></span>
        </div>
    );

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_58me40n', 'template_8r9wza9', form.current, 'zmGO9_WUxzFpFfmMJ')
            .then(
                () => {
                    NotificationManager.success('Tu petición se ha enviado con éxito.', 'Exisoto!', 3000);
                },
                () => {
                    NotificationManager.error('Error al enviar el formulario', 'Error!', 3000);
                }
            );
        setTimeout(function() {
            window.location.reload();
        },3000)
    }

    const ContactFormData = () => (
        <form ref={form} className="form-contact text-left" id="form-contact" onSubmit={sendEmail}>
            <Input inputType="text" isMaterial label="Nombre" inputName="fullname" className="input-form-auditoria form-control" required />
            <Input inputType="email" isMaterial label="Correo Electrónico" inputName="email" className="input-form-auditoria form-control" required />
            <Input inputType="number" isMaterial label="Teléfono" inputName="phone" className="input-form-auditoria form-control" required />
            <Input inputType="textarea" isMaterial label="Mensaje" inputName="message" className="input-form-auditoria form-control" required />
            <LabelTextPrivacyPolicy />
            <div className="text-left">
                <SubmitButtonGroup />
            </div>
        </form>
    );

    const ContactFormSection = () => (
        <Box {...contentWrapper}>
            <Box {...sectionHeader}>
                <Text content="Contacto" {...sectionSubTitle} />
            </Box>
            <ContactFormData />
        </Box>
    );

    const ContactInfoSection = () => (
        <Box {...contentWrapper}>
            <Box {...sectionHeader}>
                <Text content="Dónde estamos" {...sectionSubTitle} />
                <ul className="list-contact-icons text-left">
                    <li className="display-flex">
                        <FontAwesomeIcon icon={faLocationPin} size="lg" style={{ color: '#F7A61B' }} />
                        <span className="ml-4">Carrer Camí Antic de Vic, 17, 08520 Corró d'Avall, Barcelona</span>
                    </li>
                    <li className="display-flex mt-4">
                        <FontAwesomeIcon icon={faPhone} size="lg" style={{ color: '#F7A61B' }} />
                        <a href="tel://93 849 87 18" className="ml-3">
                            93 849 87 18
                        </a>
                    </li>
                    <li className="display-flex mt-4">
                        <FontAwesomeIcon icon={faEnvelope} size="lg" style={{ color: '#F7A61B' }} />
                        <a href="mailto:info@farmaoffice.com" className="ml-3">
                            info@sirventproductes.com
                        </a>
                    </li>
                </ul>
            </Box>
            <Box {...sectionHeader}>
                <Text content="Horario" {...sectionSubTitle} />
                <ul className="list-contact-icons text-left">
                    <li className="display-flex">
                        <FontAwesomeIcon icon={faClock} size="lg" style={{ color: '#F7A61B' }} />
                        <span className="ml-3">Lunes a Viernes de 08:00h a 13:00h y de 15:00 a 18:00</span>
                    </li>
                </ul>
            </Box>
        </Box>
    );

    return (
        <ContactArea id="ContactSection">
            <Container>
                <Box className="row" {...row}>
                    <Box className="col tabCol" {...col}>
                        <ContactFormSection />
                    </Box>
                    <Box className="col tabCol" {...col}>
                        <ContactInfoSection />
                    </Box>
                    <Box className="text-center w-100">
                        <GoogleMaps />
                    </Box>
                </Box>
            </Container>
        </ContactArea>
  );
};

// LoginModal style props
ContactPage.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    hintTextStyle: PropTypes.object,
    contentWrapper: PropTypes.object,
    sectionHeader: PropTypes.object,
};

// AuditoriaModal default style
ContactPage.defaultProps = {
    // Team member row default style
    row: {
        flexBox: true,
        flexWrap: 'wrap',
    },
    col: {
        width: [1, 1 / 2],
    },
    sectionHeader: {
        mb: ['40px', '56px'],
    },
    // Content wrapper style
    contentWrapper: {
        pt: ['32px', '56px'],
        pl: ['17px', '32px', '38px', '40px', '56px'],
        pr: '32px',
        pb: ['32px', '56px']
    },
    // Default button style
    btnStyle: {
        minWidth: '156px',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'left',
    },
    // Outline button outline style
    outlineBtnStyle: {
        minWidth: '156px',
        fontSize: '14px',
        fontWeight: '500',
        color: '#F7A61B',
    },
    // sub section default style
    sectionSubTitle: {
        as: 'h1',
        display: 'block',
        textAlign: 'left',
        fontSize: '22px',
        letterSpacing: '0.15em',
        fontWeight: '700',
        color: '#F7A61B',
        mb: '10px',
    },
};

export default ContactPage;
