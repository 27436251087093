import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { agencyTheme } from 'common/theme/agency';
import { ResetCSS } from 'common/assets/css/style';
import { GlobalStyle, AgencyWrapper } from 'containers/Home/home.style';
import Navbar from 'containers/Home/Navbar';
import Footer from 'containers/Home/Footer';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import Seo from 'components/seo';
import 'common/assets/css/index.css';
import Contact from "containers/Home/ContactPage";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

const ContactPage = () => {
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <Seo title="Contactar | Sirvent Productes" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Contact />
          <Footer />
          <NotificationContainer />
        </AgencyWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default ContactPage;
